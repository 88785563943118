<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="材料名称:">
        <el-input v-model.trim="searchForm.materialName" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="规格:">
        <el-input v-model.trim="searchForm.specifications" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="企业名称:">
        <el-input v-model.trim="searchForm.compName" clearable placeholder="请输入"/>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="stockStatistics"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData"
    >
      <vxe-column width="44" />
      <vxe-column type="seq" title="序号" width="80" />
      <vxe-column field="compName" title="企业名称" min-width="170" />
      <vxe-column field="bajarid" title="楼号" min-width="140" />
      <vxe-column field="warehouseNo" title="库位" min-width="123" />
      <vxe-column field="materialName" title="材料名称" min-width="140" />
      <vxe-column field="specifications" title="规格" min-width="116" />
      <vxe-column field="carCount" title="车数(辆)" min-width="116" />
      <vxe-column field="grossWeight" title="毛重" min-width="116" />
      <vxe-column field="tareWeight" title="皮重" min-width="116" />
      <vxe-column field="weight" title="净重" min-width="116" />
      <vxe-column field="checkWeight" title="核实净重" min-width="116" />
      <vxe-column field="autoUsed" title="自动消耗" min-width="116" />
      <vxe-column field="thisYearTheoryWeight" title="手动消耗" min-width="116" />
      <vxe-column field="handUsed" title="总消耗" min-width="116" />
      <vxe-column field="allowance" title="余量" min-width="116" />
    </BaseTable>
  </PageContainer>
</template>

<script>
import {
  materialStockList
} from '@/apis/stock/stockStatistics'
export default {
  data () {
    return {
      searchForm: {
        materialName: '',
        specifications: '',
        compName: ''
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      totals: {
        lowCompCount: 0,
        highCompCount: 0,
        totalWeight: 0,
        totalTheoryWeight: 0
      },
      loading: false,
      editData: null,
      detailVisible: false,
      sortVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    async initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      const res = await materialStockList(params)
      this.loading = false
      if (res.code == 200) {
        this.tableData = res.data.rows
        this.tablePage.totalSize = res.data.totalSize
      } else {
        this.$message.error(res.msg)
      }
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },
    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    }
  }
}
</script>
